import { FILE_TYPES, getFileExtension } from 'helpers/validations/constants';

export const isObject = (value: Record<string, unknown>): boolean => {
  return value === Object(value) && !Array.isArray(value) && typeof value !== 'function';
};

export const camelCase = (value: string): string =>
  value.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });

export const snakeCase = (value: string): string =>
  value.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const parseObjectKeys = (
  value: Record<string, unknown>,
  callback: (value: string) => string,
): unknown => {
  if (isObject(value)) {
    const object: Record<string, unknown> = {};
    Object.keys(value).forEach((key) => {
      object[callback(key)] = parseObjectKeys(value[key] as Record<string, unknown>, callback);
    });
    return object;
  }
  if (Array.isArray(value)) return value.map((item) => parseObjectKeys(item, callback));
  return value;
};

export const camelCaseKeys = (value: Record<string, unknown>): unknown =>
  parseObjectKeys(value, camelCase);

export const snakeCaseKeys = (value: Record<string, unknown>): unknown =>
  parseObjectKeys(value, snakeCase);

export const groupArrayByKey = <T>(array: Array<T>, key: string): Record<string, Array<T>> =>
  array.reduce(
    (hash: Record<string, Array<T>>, obj: T) => ({
      ...hash,
      [obj[key as never] as string]: ((hash[obj[key as never]] as Array<T>) || []).concat(obj),
    }),
    {},
  );

export const getUrlExtension = (url: string) => {
  try {
    // eslint-disable-next-line no-useless-escape
    return url.match(/^https?:\/\/.*[\\\/][^\?#]*\.([a-zA-Z0-9]+)\??#?/)?.[1];
  } catch (ignored) {
    return false;
  }
};

export const base64PDFToBlobUrl = (base64: string) => {
  const binStr = atob(base64.split(',')[1]);
  const len = binStr.length;
  const arr = new Uint8Array(len);
  for (let i = 0; i < len; i++) arr[i] = binStr.charCodeAt(i);
  const blob = new Blob([arr], { type: FILE_TYPES.pdf });
  return URL.createObjectURL(blob);
};

export const getBase64FilenameWithExtension = (base64: string, filename: string) => {
  const base64Array = base64.split(',');
  const mime = base64Array[0].match(/:(.*?);/)?.[1] || '';
  return `${filename.replace(' ', '-').toLowerCase()}.${getFileExtension(mime)}`;
};

export const base64ToFile = (base64: string, filename: string) => {
  const base64Array = base64.split(',');
  const mime = base64Array[0].match(/:(.*?);/)?.[1] || '';
  const binStr = atob(base64Array[base64Array.length - 1]);
  const len = binStr.length;
  const arr = new Uint8Array(len);
  for (let i = 0; i < len; i++) arr[i] = binStr.charCodeAt(i);
  return new File([arr], getBase64FilenameWithExtension(base64, filename), { type: mime });
};
