import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'PRODUCTS_FORM', {
  TITLE: 'Products',
  NAME: 'Name',
  DESCRIPTION: 'Description',
  CATEGORIES: 'Categories',
  TAGS: 'Tags',
  STOCK: 'Stock',
  PRICE: 'Price',
  SUPPLIER: 'Supplier',
  COST: 'Cost',
  QUANTITY_LIMIT: 'Quantity Limit',
  SHOW_AS_NEW: 'Show as new',
  SKU: 'SKU',
  DROP_SHIPPING: 'Drop Shipping',
  OFFER_PRICE: 'Offer Price',
  SHOW_ON_NEW_14: 'Show 14 days on New Arrivals',
  IMAGE1: 'Image 1',
  IMAGE2: 'Image 2',
  IMAGE3: 'Image 3',
  IMAGE4: 'Image 4',
  IMAGE5: 'Image 5',
  IMAGE6: 'Image 6',
  COMBINATION: 'Combination',
  PRODUCT_DETAIL: 'Product Detail',
  FEATURE_TYPE: 'Feature Type',
  COLOR_SELECT: 'Select color',
  SIZE_SELECT: 'Select size',
  DESIGN_SELECT: 'Select design',
  STOCK_CATEGORY: 'Stock Category',
  STOCK_DESCRIPTION: 'Stock Description',
  COMBINATION_BUTTON: 'Add combination',
  PRODUCT_DETAIL_BUTTON: 'Add product detail',
  SUBMIT_BUTTON: 'Save',
  LOADING: 'Loading...',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'PRODUCTS_FORM', {
  TITLE: 'Productos',
  NAME: 'Nombre',
  DESCRIPTION: 'Descripción',
  CATEGORIES: 'Categorías',
  TAGS: 'Etiquetas',
  STOCK: 'Stock',
  PRICE: 'Precio',
  SUPPLIER: 'Proveedor',
  COST: 'Costo',
  QUANTITY_LIMIT: 'Límite de cantidad',
  SHOW_AS_NEW: 'Mostrar como nuevo',
  SKU: 'SKU',
  DROP_SHIPPING: 'Drop Shipping',
  OFFER_PRICE: 'Precio de oferta',
  SHOW_ON_NEW_14: 'Mostrar 14 dias en Nuevos Ingresos',
  IMAGE1: 'Imagen 1',
  IMAGE2: 'Imagen 2',
  IMAGE3: 'Imagen 3',
  IMAGE4: 'Imagen 4',
  IMAGE5: 'Imagen 5',
  IMAGE6: 'Imagen 6',
  COMBINATION: 'Combinación',
  PRODUCT_DETAIL: 'Detalle del producto',
  STOCK_CATEGORY: 'Stock Categoría',
  FEATURE_TYPE: 'Tipo de Característica',
  COLOR_SELECT: 'Seleccionar color',
  SIZE_SELECT: 'Seleccionar talle / medida',
  DESIGN_SELECT: 'Seleccionar diseño',
  STOCK_DESCRIPTION: 'Stock Descripción',
  COMBINATION_BUTTON: 'Agregar combinación',
  PRODUCT_DETAIL_BUTTON: 'Agregar detalle del producto',
  SUBMIT_BUTTON: 'Guardar',
  LOADING: 'Cargando...',
});
