import styled, { css } from 'styled-components';

import { COLORS } from 'helpers/enums/colors';

import Button from 'components/commons/Button';

export const FormContent = styled.form`
  margin-top: 8px;
  width: 100%;
`;

export const FormFieldset = styled.fieldset`
  padding: 0;
  border: none;
`;

export const FormRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const FormColumnsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
`;

export const ColFormRowContainer = styled.div`
  display: flex;
  flex: 0 0 48%;
  max-width: 48%;
`;

export const FormButtonContainer = styled.div`
  ${({ auth, col }: { auth?: boolean; col?: boolean }) =>
    auth
      ? css`
          position: fixed;
          left: 0;
          bottom: 0;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          padding: 16px;
          background-color: ${COLORS.white};
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
          z-index: 1;

          button {
            margin-left: 16px;
          }
        `
      : css`
          display: flex;
          flex-direction: ${col ? 'row' : 'column'};
          justify-content: end;
          align-items: center;
          margin-bottom: 8px;
        `}
  && {
    Button {
      margin-left: ${({ col }: { col?: boolean }) => (col ? '16px' : '0')};
    }
  }
`;

export const FormAddCombinationButton = styled(Button)`
  && {
    width: 100%;
    margin-bottom: 24px;
  }
`;

export const FormSubmitButton = styled(Button)`
  && {
    width: 100%;
  }
`;
