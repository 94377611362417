import { Reducer } from 'redux';

import { ICategoriesState } from 'types/categories.types';
import * as authConstants from 'redux/constants/auth.constants';
import * as constants from 'redux/constants/categories.constants';

export const defaultState: ICategoriesState = {
  count: 0,
  items: [],
  loading: false,
  selectedItem: null,
  table: {
    page: 1,
    limit: 10,
    search: '',
    sortKey: 'name',
    sortType: 'asc',
  },
};

const categoriesReducer: Reducer = (state = defaultState, action) => {
  const { data, type } = action;
  switch (type) {
    case constants.CATEGORIES_ON_DELETE_REQUESTED:
    case constants.CATEGORIES_ON_GET_ALL_REQUESTED:
    case constants.CATEGORIES_ON_SAVE_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case constants.CATEGORIES_ON_GET_ONE_REQUESTED:
      return {
        ...state,
        loading: !!action.id,
        selectedItem: null,
      };
    case constants.CATEGORIES_ON_GET_ALL_SUCCEEDED:
      return {
        ...state,
        count: data.count,
        items: data.items,
        loading: false,
        table: data.table,
      };
    case constants.CATEGORIES_ON_GET_ONE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        selectedItem: data.item,
      };
    case constants.CATEGORIES_ON_DELETE_SUCCEEDED:
    case constants.CATEGORIES_ON_SAVE_SUCCEEDED:
    case constants.CATEGORIES_ON_DELETE_FAILED:
    case constants.CATEGORIES_ON_GET_ALL_FAILED:
    case constants.CATEGORIES_ON_SAVE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case constants.CATEGORIES_ON_GET_ONE_FAILED:
      return {
        ...state,
        loading: false,
        selectedItem: null,
      };
    case authConstants.AUTH_ON_LOGOUT_SUCCEEDED:
      return defaultState;
    default:
      return state;
  }
};

export default categoriesReducer;
