import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import Table from 'components/commons/Table';

import { ViewContainer, ViewContent } from 'styles/view';

import { IOrdersViewProps } from './types'; // Cambia a la interfaz de pedidos

import './i18n';

const OrdersView: FunctionComponent<IOrdersViewProps> = (props: IOrdersViewProps) => {
  const { columns, count, data, loading, onAddClick, onTableUpdate, table } = props;

  return (
    <ViewContainer auth data-testid="orders-view">
      <ViewContent auth>
        <Table
          columns={columns}
          count={count}
          data={data}
          loading={loading}
          onAddClick={onAddClick}
          onTableUpdate={onTableUpdate}
          title={i18next.t<string>('ORDERS_VIEW:TITLE')}
          table={table}
          enableSearch={false}
          enableCreate={false}
        />
      </ViewContent>
    </ViewContainer>
  );
};

export default OrdersView;
