import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'ORDERS_VIEW', {
  TITLE: 'Orders',
  ORDER_ID: 'Order ID',
  PRODUCT_NAME: 'Product Name',
  PRICE: 'Price',
  QUANTITY: 'Quantity',
  STATUS: 'Status',
  ACTIONS: 'Actions',
  EDIT: 'Edit',
  DELETE: 'Delete',
  DELETE_DIALOG_TITLE: 'Delete order',
  DELETE_DIALOG_MESSAGE: 'Are you sure you want to delete the order {{ id }}?',
  DATE: 'Date',
  PURCHASE_DATE: 'Purchase Date',
  TOTAL_PLUS_SHIPPING: 'Total + Shipping',
  CUSTOMER: 'Customer',
  EMAIL: 'Email',
  ORDER_STATE: 'Order State',
  USER_ID: 'User ID',
  SHIPPING_METHOD: 'Shipping Method',
  SUBTOTAL: 'Subtotal',
  WEB_PURCHASE: 'Web Purchase',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'ORDERS_VIEW', {
  TITLE: 'Pedidos',
  ORDER_ID: 'ID del pedido',
  PRODUCT_NAME: 'Nombre del producto',
  PRICE: 'Precio',
  QUANTITY: 'Cantidad',
  STATUS: 'Estado',
  ACTIONS: 'Acciones',
  EDIT: 'Editar',
  DELETE: 'Eliminar',
  DELETE_DIALOG_TITLE: 'Eliminar pedido',
  DELETE_DIALOG_MESSAGE: '¿Estás seguro de querer eliminar el pedido {{ id }}?',
  DATE: 'Fecha',
  PURCHASE_DATE: 'Fecha de compra',
  TOTAL_PLUS_SHIPPING: 'Total + Envío',
  CUSTOMER: 'Cliente',
  EMAIL: 'Correo electrónico',
  ORDER_STATE: 'Estado del pedido',
  USER_ID: 'ID de usuario',
  SHIPPING_METHOD: 'Forma de envio',
  SUBTOTAL: 'Subtotal',
  WEB_PURCHASE: 'Compra web',
});
