import { createSelector } from 'reselect';

import { IProductsState } from 'types/products.types';

const getItems = (state: { products: IProductsState }) => state.products.items;
const getSelectedItem = (state: { products: IProductsState }) => state.products.selectedItem;
const getCategories = (state: { products: IProductsState }) => state.products.categories;
const getSuppliers = (state: { products: IProductsState }) => state.products.suppliers;
const getStockCategories = (state: { products: IProductsState }) => state.products.stockCategories;
const getStockDescriptions = (state: { products: IProductsState }) =>
  state.products.stockDescriptions;
const getTags = (state: { products: IProductsState }) => state.products.tags;

export const productsSelector = createSelector([getItems], (products) =>
  products.map((product) => ({
    ...product,
    supplierName: product.suppliers[0]?.name,
  })),
);

export const productsFormSelector = createSelector([getSelectedItem], (product) => ({
  name: product?.name || '',
  description: product?.description || '',
  categories: product?.categories?.map((category) => category.id.toString()) || [],
  tags: product?.tags?.map((tag) => tag.id.toString()) || [],
  stock: product?.stock || '',
  price: product?.price || '',
  supplierId: product?.suppliers[0]?.id?.toString() || '',
  costo: product?.costo || '',
  sku: product?.sku || '',
  qtyBuy: product?.qtyBuy || '',
  sale: product?.sale || '',
  showtime: product?.showtime || '',
  dropShipping: product?.dropShipping || '',
  new: product?.new || '',
  stockCombinations: product?.combinations
    ? Object.values(product.combinations).map((combinations) => {
        const firstCombination = combinations.shift();
        const stockCombination = {
          combinations: combinations.map((combination) => ({
            stock: combination.stock.toString(),
            stockCategory: combination.stockDescriptions?.stockCategoryId?.toString(),
            stockDescription: combination.stockDescriptions?.id?.toString(),
          })),
          stock: firstCombination?.stock.toString(),
          stockCategory: firstCombination?.stockDescriptions?.stockCategoryId?.toString(),
          stockDescription: firstCombination?.stockDescriptions?.id?.toString(),
        };
        return stockCombination;
      })
    : [],
}));

export const productsFilesSelector = createSelector([getSelectedItem], (product) => ({
  image1: product?.image1 || '',
  image2: product?.image2 || '',
  image3: product?.image3 || '',
  image4: product?.image4 || '',
  image5: product?.image5 || '',
  image6: product?.image6 || '',
}));

export const productsCategoriesSelector = createSelector([getCategories], (categories) =>
  categories.map((category) => ({
    label: category.name,
    value: category.id.toString(),
  })),
);

export const productsSuppliersSelector = createSelector([getSuppliers], (suppliers) =>
  suppliers.map((supplier) => ({
    label: supplier.name,
    value: supplier.id.toString(),
  })),
);

export const productsStockCategoriesSelector = createSelector(
  [getStockCategories],
  (stockCategories) =>
    stockCategories.map((stockCategory) => ({
      label: stockCategory.name,
      value: stockCategory.id.toString(),
    })),
);

export const productsStockDescriptionsSelector = createSelector(
  [getStockDescriptions],
  (stockDescriptions) =>
    stockDescriptions.map((stockDescription) => ({
      label: stockDescription.name,
      value: stockDescription.id.toString(),
      data: stockDescription.stockCategoryId.toString(),
    })),
);

export const productsTagsSelector = createSelector([getTags], (tags) =>
  tags.map((tag) => ({
    label: tag.name,
    value: tag.id.toString(),
  })),
);
