import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { IAppState } from 'types/app.types';
import { IAuthState } from 'types/auth.types';
import { ICategoriesState } from 'types/categories.types';
import { IPermissionsState } from 'types/permissions.types';
import { IProductsState } from 'types/products.types';
import { IRolesState } from 'types/roles.types';
import { IUsersState } from 'types/users.types';
import { IOrdersState } from 'types/orders.types';

export interface IRootState {
  app: IAppState;
  auth: IAuthState;
  categories: ICategoriesState;
  permissions: IPermissionsState;
  products: IProductsState;
  roles: IRolesState;
  users: IUsersState;
  orders: IOrdersState;
}

const useTypedSelector: TypedUseSelectorHook<IRootState> = useSelector;

export default useTypedSelector;
