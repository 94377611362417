import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'ORDER_DETAILS_VIEW', {
  TITLE: 'Order details',
  NO_ORDER_FOUND: 'No order found',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'ORDER_DETAILS_VIEW', {
  TITLE: 'Detalles del pedido',
  NO_ORDER_FOUND: 'No se encontró el pedido',
});
