import { createSelector } from 'reselect';

import { IAuthState } from 'types/auth.types';

const getUser = (state: { auth: IAuthState }) => state.auth.data;

export const userSelector = createSelector([getUser], (user) => user);

export const isUserLoggedInSelector = createSelector([userSelector], (user) => !!user?.id);

export const userRolesSelector = createSelector(
  [getUser],
  (user) => user?.roles?.map((role) => role.name) || [],
);
