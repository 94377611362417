import React, { FunctionComponent } from 'react';
import { Redirect, Switch, SwitchProps } from 'react-router-dom';

import Route from 'containers/RouteContainer';
import LoginContainer from 'containers/LoginContainer';
import DashboardContainer from 'containers/DashboardContainer';
import PermissionsContainer from 'containers/PermissionsContainer';
import PermissionsFormContainer from 'containers/PermissionsFormContainer';
import ProductsContainer from 'containers/ProductsContainer';
import ProductsFormContainer from 'containers/ProductsFormContainer';
import RolesContainer from 'containers/RolesContainer';
import RolesFormContainer from 'containers/RolesFormContainer';
import UsersContainer from 'containers/UsersContainer';
import UsersFormContainer from 'containers/UsersFormContainer';
import OrdersContainer from 'containers/OrdersContainer';
import OrdersFormContainer from 'containers/OrdersFormContainer';
import OrderDetailsContainer from 'containers/OrderDetailsContainer';
import ErrorView from 'views/ErrorView';

const NavigationContainer: FunctionComponent<SwitchProps> = () => (
  <Switch>
    <Redirect exact from="/" to="/dashboard" />
    {/* Public Routes */}
    <Route exact path="/login" component={LoginContainer} restricted />
    {/* Private Routes */}
    <Route exact path="/dashboard" component={DashboardContainer} authenticated />
    <Route exact path="/products" component={ProductsContainer} authenticated />
    <Route exact path="/products/create" component={ProductsFormContainer} authenticated />
    <Route exact path="/products/:id/edit" component={ProductsFormContainer} authenticated />
    <Route exact path="/orders" component={OrdersContainer} authenticated />
    <Route path="/orders/:id/edit" component={OrdersFormContainer} authenticated />
    <Route path="/orders/:id/show" component={OrderDetailsContainer} authenticated />
    <Route exact path="/users/list" component={UsersContainer} authenticated />
    <Route exact path="/users/create" component={UsersFormContainer} authenticated />
    <Route exact path="/users/:id/edit" component={UsersFormContainer} authenticated />
    <Route exact path="/users/permissions/list" component={PermissionsContainer} authenticated />
    <Route
      exact
      path="/users/permissions/create"
      component={PermissionsFormContainer}
      authenticated
    />
    <Route
      exact
      path="/users/permissions/:id/edit"
      component={PermissionsFormContainer}
      authenticated
    />
    <Route exact path="/users/roles/list" component={RolesContainer} authenticated />
    <Route exact path="/users/roles/create" component={RolesFormContainer} authenticated />
    <Route exact path="/users/roles/:id/edit" component={RolesFormContainer} authenticated />
    {/* Default Route */}
    <Route component={ErrorView} />
  </Switch>
);

export default NavigationContainer;
