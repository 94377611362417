import { createSelector } from 'reselect';

import { IOrdersState } from 'types/orders.types';

const selectOrders = (state: { orders: IOrdersState }) => state.orders;
const getSelectedItem = (state: { orders: IOrdersState }) => state.orders.selectedItem;
const getOrderStates = (state: { orders: IOrdersState }) => state.orders.ordersStates;
const getActiveOrderState = (state: { orders: IOrdersState }) => state.orders.activeOrderState;

export const ordersSelector = createSelector([selectOrders], (orders) => orders);

export const ordersFormSelector = createSelector([getSelectedItem], (order) => ({
  id: order?.id || '',
  orderState: order?.orderState || '',
}));

export const orderStatesSelector = createSelector([getOrderStates], (orderStates) => {
  return orderStates;
});

export const activeOrderStateSelector = createSelector(
  [getActiveOrderState],
  (activeOrderState) => {
    return activeOrderState;
  },
);
