import React, { FunctionComponent } from 'react';
import { Grid, Typography, Box, Paper } from '@mui/material';
import { ViewContainer, ViewContent } from 'styles/view';
import { IOrderDetailsViewProps } from './types';

const OrderDetailsView: FunctionComponent<IOrderDetailsViewProps> = ({ order }) => {
  return (
    <ViewContainer auth data-testid="orders-details-view">
      <ViewContent auth>
        <Box sx={{ width: '100%', maxWidth: '1200px', margin: 'auto', padding: '20px' }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333', mb: 2 }}>
            Orden #{order?.orderHeader?.id ?? 'N/A'}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            Fecha: {order?.orderHeader?.date ?? 'N/A'}
          </Typography>

          <Grid container spacing={3} alignItems="stretch">
            <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
              <Paper
                elevation={3}
                sx={{
                  px: 4,
                  py: 3,
                  borderRadius: 2,
                  width: '100%',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': { transform: 'scale(1.02)' },
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                  Detalles del Pedido
                </Typography>
                <Typography variant="body1">
                  <strong>ID:</strong> {order?.orderHeader?.id ?? 'N/A'}
                </Typography>
                <Typography variant="body1">
                  <strong>Estado del Pedido:</strong> {order?.orderHeader?.orderState ?? 'N/A'}
                </Typography>
                <Typography variant="body1">
                  <strong>Forma de Pago:</strong>{' '}
                  {order?.orderHeader?.paymentMethod ?? 'No asignado'}
                </Typography>
                <Typography variant="body1">
                  <strong>Total:</strong> ${order?.orderHeader?.total ?? 0}
                </Typography>
                <Typography variant="body1">
                  <strong>Total Costo:</strong> ${order?.orderHeader?.costoEnvio ?? 0}
                </Typography>
                <Typography variant="body1">
                  <strong>Descuento:</strong> {order?.orderHeader?.discount ?? 0}%
                </Typography>
                <Typography variant="body1">
                  <strong>Cupon de Descuento:</strong>{' '}
                  {order?.orderHeader?.discountCoupon ?? 'No tiene cupon'}
                </Typography>
                <Typography variant="body1">
                  <strong>Motivo de Cancelación:</strong>{' '}
                  {order?.orderHeader?.reason ?? 'Sin motivo'}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
              <Paper
                elevation={3}
                sx={{
                  px: 4,
                  py: 3,
                  borderRadius: 2,
                  width: '100%',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': { transform: 'scale(1.02)' },
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                  Datos del Cliente
                </Typography>
                <Typography variant="body1">
                  <strong>Nombre Retira:</strong>{' '}
                  {order?.orderHeader?.shippingDetails?.firstNameRetire ?? '-'}
                </Typography>
                <Typography variant="body1">
                  <strong>Apellido Retira:</strong>{' '}
                  {order?.orderHeader?.shippingDetails?.lastNameRetire ?? '-'}
                </Typography>
                <Typography variant="body1">
                  <strong>Celular Retira:</strong>{' '}
                  {order?.orderHeader?.shippingDetails?.phoneRetire ?? '-'}
                </Typography>
                <Typography variant="body1">
                  <strong>Email Retira:</strong>{' '}
                  {order?.orderHeader?.shippingDetails?.emailRetire ?? '-'}
                </Typography>
              </Paper>
            </Grid>
          </Grid>

          <Box sx={{ mt: 4 }}>
            <Paper
              elevation={3}
              sx={{
                px: 4,
                py: 3,
                borderRadius: 2,
                width: '100%',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': { transform: 'scale(1.02)' },
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Productos
              </Typography>
              {order?.orderDetails?.map((item) => (
                <Box
                  key={item?.id ?? Math.random()}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '10px 0',
                    borderBottom: '1px solid #e0e0e0',
                  }}
                >
                  {item?.image && (
                    <img
                      src={item.image}
                      alt={item?.productName ?? 'Producto'}
                      style={{
                        width: 80,
                        height: 80,
                        objectFit: 'cover',
                        borderRadius: 8,
                        marginRight: 20,
                      }}
                    />
                  )}
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="body1" fontWeight="bold">
                      {item?.productName ?? 'N/A'}
                    </Typography>
                    <Typography variant="body1">Cantidad: {item?.quantity ?? 0}</Typography>
                    <Typography variant="body1">Precio: ${item?.price ?? 0}</Typography>
                    <Typography variant="body1">
                      Subtotal: ${item?.price && item?.quantity ? item.price * item.quantity : 0}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Paper>
          </Box>
          {/* Sección Datos del Remitente */}
          <Box sx={{ mt: 4 }}>
            <Paper
              elevation={3}
              sx={{
                px: 4,
                py: 3,
                borderRadius: 2,
                width: '100%',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': { transform: 'scale(1.02)' },
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Datos del Remitente
              </Typography>
              <Typography variant="body1">
                <strong>Nombre:</strong> CRACO
              </Typography>
              <Typography variant="body1">
                <strong>Dirección:</strong> CAMARGO 136
              </Typography>
              <Typography variant="body1">
                <strong>Horarios de Atención:</strong> Lunes a viernes de 8 a 17hs
              </Typography>
              <Typography variant="body1">
                <strong>Teléfono:</strong> 11 3068 0017
              </Typography>
              <Typography variant="body1">
                <strong>Email:</strong> ventas@craco.com.ar
              </Typography>
              <Typography variant="body1">
                <strong>País:</strong> Argentina
              </Typography>
            </Paper>
          </Box>
        </Box>
      </ViewContent>
    </ViewContainer>
  );
};

export default OrderDetailsView;
