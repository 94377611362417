import { all } from 'redux-saga/effects';

import { watchAuth } from 'redux/sagas/auth.sagas';
import { watchCategories } from 'redux/sagas/categories.sagas';
import { watchPermissions } from 'redux/sagas/permissions.sagas';
import { watchProducts } from 'redux/sagas/products.sagas';
import { watchRoles } from 'redux/sagas/roles.sagas';
import { watchUsers } from 'redux/sagas/users.sagas';
import { watchOrders } from 'redux/sagas/orders.sagas';

export default function* rootSaga(): Generator {
  yield all([
    watchAuth(),
    watchCategories(),
    watchPermissions(),
    watchProducts(),
    watchRoles(),
    watchUsers(),
    watchOrders(),
  ]);
}
