import styled, { css } from 'styled-components';
import { IconButton } from '@mui/material';

import { SIZES } from 'helpers/enums/sizes';
import { COLORS } from 'helpers/enums/colors';

export const ViewContainer = styled.div<{ auth?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  height: ${({ auth }) => (auth ? 'auto' : '100%')};
  ${({ auth }) =>
    !auth &&
    css`
      justify-content: center;
      height: 100%;
      background: url('img/background.jpg') no-repeat center center;
      background-size: cover;
    `}
  padding: 25px;
`;

export const ViewContent = styled.div<{ auth?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ auth }) =>
    auth
      ? css`
          flex: 1;
          width: 100%;
        `
      : css`
          width: 50%;
        `}
  padding: 23px 24px;
  background-color: ${COLORS.white};
  border-radius: 6px;
  box-shadow: ${COLORS.boxShadow};

  ${({ auth }) =>
    !auth &&
    css`
      @media (max-width: ${SIZES.desktop}px) {
        width: 70%;
      }
    `}

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  @media (max-width: ${SIZES.mediumPhone}px) {
    gap: 20px;
  }
`;

export const Title = styled.h1`
  margin-top: 0;
  margin-bottom: 20px;
  color: ${COLORS.orangeOutrageous};
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${SIZES.smallTablet}px) {
    text-align: left;
  }

  @media (max-width: ${SIZES.mediumPhone}px) {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    font-size: 24px;
  }
`;

export const TableContainer = styled.table`
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid ${COLORS.grayAlto};
  border-collapse: collapse;
  border-spacing: 0;

  td {
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: ${COLORS.grayPorcelain};
  }
`;

export const CustomIconButton = styled(IconButton)`
  && {
    color: ${({ danger }: { danger?: boolean; nomarginbottom?: boolean }) =>
      danger ? COLORS.redPersian : COLORS.orangeOutrageous};
    ${({ nomarginbottom }: { danger?: boolean; nomarginbottom?: boolean }) =>
      !nomarginbottom &&
      css`
        margin-bottom: 20px;
      `}
  }
`;

export const FilePreview = styled.iframe`
  width: 100%;
  height: 500px;
  margin-top: 24px;
`;

export const ImagePreview = styled.img`
  width: 300px;
  margin-top: 20px;
  align-self: center;
`;

export const Text = styled.span`
  color: gray;
  margin: 5px 0;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const ProductContainer = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
`;

export const RequiredAsterisk = styled.span`
  color: red;
  margin-left: 4px;
  font-size: 1rem;
  font-weight: bold;
  verticalalign: middle;
  display: inline-block;
`;
