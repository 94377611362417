import { combineReducers } from 'redux';

import appReducer from 'redux/reducers/app.reducer';
import authReducer from 'redux/reducers/auth.reducer';
import categoriesReducer from 'redux/reducers/categories.reducer';
import permissionsReducer from 'redux/reducers/permissions.reducer';
import productsReducer from 'redux/reducers/products.reducer';
import rolesReducer from 'redux/reducers/roles.reducer';
import usersReducer from 'redux/reducers/users.reducer';
import ordersReducer from './orders.reducer';

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  categories: categoriesReducer,
  permissions: permissionsReducer,
  products: productsReducer,
  roles: rolesReducer,
  users: usersReducer,
  orders: ordersReducer,
});

export default rootReducer;
