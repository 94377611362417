export const ORDERS_ON_DELETE_REQUESTED = 'ORDERS_ON_DELETE_REQUESTED';
export const ORDERS_ON_DELETE_SUCCEEDED = 'ORDERS_ON_DELETE_SUCCEEDED';
export const ORDERS_ON_DELETE_FAILED = 'ORDERS_ON_DELETE_FAILED';

export const ORDERS_ON_GET_ALL_REQUESTED = 'ORDERS_ON_GET_ALL_REQUESTED';
export const ORDERS_ON_GET_ALL_SUCCEEDED = 'ORDERS_ON_GET_ALL_SUCCEEDED';
export const ORDERS_ON_GET_ALL_FAILED = 'ORDERS_ON_GET_ALL_FAILED';

export const ORDERS_ON_GET_FORM_REQUESTED = 'ORDERS_ON_GET_FORM_REQUESTED';
export const ORDERS_ON_GET_FORM_SUCCEEDED = 'ORDERS_ON_GET_FORM_SUCCEEDED';
export const ORDERS_ON_GET_FORM_FAILED = 'ORDERS_ON_GET_FORM_FAILED';

export const ORDERS_ON_GET_ONE_REQUESTED = 'ORDERS_ON_GET_ONE_REQUESTED';
export const ORDERS_ON_GET_ONE_SUCCEEDED = 'ORDERS_ON_GET_ONE_SUCCEEDED';
export const ORDERS_ON_GET_ONE_FAILED = 'ORDERS_ON_GET_ONE_FAILED';

export const ORDERS_ON_SAVE_REQUESTED = 'ORDERS_ON_SAVE_REQUESTED';
export const ORDERS_ON_SAVE_SUCCEEDED = 'ORDERS_ON_SAVE_SUCCEEDED';
export const ORDERS_ON_SAVE_FAILED = 'ORDERS_ON_SAVE_FAILED';
