import { AxiosResponse } from 'axios';

import { get, post, remove } from 'services/api';

import { IOrdersFormData } from 'types/orders.types';

export const getAll = (
  limit: number,
  page: number,
  search: string,
  sortKey: string,
  sortType: string,
): Promise<AxiosResponse> =>
  get(
    `/orders/getAll?limit=${limit}&page=${page}&search=${search}&sortKey=${sortKey}&sortType=${sortType}`,
  );

export const getForm = (): Promise<AxiosResponse> => {
  return get('orders/form');
};

export const getOne = (id: string): Promise<AxiosResponse> => get(`orders/${id}`);

export const save = (data: IOrdersFormData, id?: string): Promise<AxiosResponse> => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      formData.append(key, String(value));
    }
  });

  if (id) formData.append('_method', 'PUT');
  return post(`orders${!id ? '' : `/${id}`}`, formData, { transformRequest: () => formData });
};

export const softDelete = (id: string): Promise<AxiosResponse> => remove(`orders/${id}`);
