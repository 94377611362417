import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'ORDERS_FORM', {
  TITLE: 'Update Order',
  CUSTOMER: 'Customer',
  ORDER_STATE: 'Order State',
  TOTAL: 'Total',
  COMMENTS: 'Comments',
  LOADING: 'Loading...',
  SUBMIT_BUTTON: 'Save Order',
  UPDATE_BUTTON: 'Update Order',
  CREATE_BUTTON: 'Create Order',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'ORDERS_FORM', {
  TITLE: 'Actualizar Pedido',
  CUSTOMER: 'Cliente',
  ORDER_STATE: 'Estado del Pedido',
  TOTAL: 'Total',
  COMMENTS: 'Comentarios',
  LOADING: 'Cargando...',
  SUBMIT_BUTTON: 'Guardar Pedido',
  UPDATE_BUTTON: 'Actualizar Pedido',
  CREATE_BUTTON: 'Crear Pedido',
});
