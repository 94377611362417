import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { IOrdersFormData } from 'views/OrdersView/components/OrdersForm/types';
import { onGetOne, onSave, onGetForm } from 'redux/actions/orders.actions';
import { orderStatesSelector, activeOrderStateSelector } from 'redux/selectors/orders.selector';

import LayoutContainer from 'containers/LayoutContainer';
import Loading from 'components/commons/Loading';

import 'views/OrdersView/components/OrdersForm/i18n';
import OrdersForm from 'views/OrdersView/components/OrdersForm';

const OrdersFormContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { loading } = useTypedSelector((state) => state.orders);
  const { id = '' } = useParams<{ id?: string }>();
  const orderStates = useTypedSelector(orderStatesSelector) ?? [];
  const isEditing = !!id;
  const activeOrderState = useTypedSelector(activeOrderStateSelector);
  const initialValues = { ordersState: activeOrderState };

  useEffect(() => {
    dispatch(onGetForm());
    if (id) {
      dispatch(onGetOne(id));
    }
    return () => {
      dispatch(onGetOne(''));
    };
  }, [dispatch, id]);

  const onSubmit = (formData: IOrdersFormData) => {
    dispatch(onSave(formData, id));
  };

  return (
    <LayoutContainer auth title={i18next.t<string>('ORDERS_FORM:TITLE')}>
      <OrdersForm
        initialValues={initialValues}
        isEditing={isEditing}
        onSubmit={onSubmit}
        orderStates={orderStates}
      />
      {loading && <Loading isModalMode message={i18next.t('ORDERS_FORM:LOADING')} />}
      {orderStates.length === 0 && !loading && (
        <Loading isModalMode message="Cargando estados de orden..." />
      )}
    </LayoutContainer>
  );
};

export default OrdersFormContainer;
