import { Middleware } from 'redux';

import * as authConstants from 'redux/constants/auth.constants';
import * as categoriesConstants from 'redux/constants/categories.constants';
import * as permissionsConstants from 'redux/constants/permissions.constants';
import * as productsConstants from 'redux/constants/products.constants';
import * as rolesConstants from 'redux/constants/roles.constants';
import * as usersConstants from 'redux/constants/users.constants';
import * as ordersConstants from 'redux/constants/orders.constants';
import { showSuccessNotification, showErrorNotification } from 'helpers/notifications';

const notificationMiddleware: Middleware = () => (next) => (action) => {
  const { data, error, type } = action;
  switch (type) {
    case authConstants.AUTH_ON_LOGIN_SUCCEEDED:
    case categoriesConstants.CATEGORIES_ON_DELETE_SUCCEEDED:
    case categoriesConstants.CATEGORIES_ON_SAVE_SUCCEEDED:
    case permissionsConstants.PERMISSIONS_ON_DELETE_SUCCEEDED:
    case permissionsConstants.PERMISSIONS_ON_SAVE_SUCCEEDED:
    case productsConstants.PRODUCTS_ON_DELETE_SUCCEEDED:
    case productsConstants.PRODUCTS_ON_SAVE_SUCCEEDED:
    case ordersConstants.ORDERS_ON_SAVE_SUCCEEDED:
    case ordersConstants.ORDERS_ON_DELETE_SUCCEEDED:
    case rolesConstants.ROLES_ON_DELETE_SUCCEEDED:
    case rolesConstants.ROLES_ON_SAVE_SUCCEEDED:
    case usersConstants.USERS_ON_DELETE_SUCCEEDED:
    case usersConstants.USERS_ON_SAVE_SUCCEEDED:
      showSuccessNotification(data.message);
      break;
    case authConstants.AUTH_ON_LOGIN_FAILED:
    case authConstants.AUTH_ON_LOGOUT_FAILED:
    case categoriesConstants.CATEGORIES_ON_DELETE_FAILED:
    case categoriesConstants.CATEGORIES_ON_GET_ALL_FAILED:
    case categoriesConstants.CATEGORIES_ON_GET_ONE_FAILED:
    case permissionsConstants.PERMISSIONS_ON_DELETE_FAILED:
    case permissionsConstants.PERMISSIONS_ON_GET_ALL_FAILED:
    case permissionsConstants.PERMISSIONS_ON_GET_ONE_FAILED:
    case productsConstants.PRODUCTS_ON_DELETE_FAILED:
    case productsConstants.PRODUCTS_ON_GET_ALL_FAILED:
    case productsConstants.PRODUCTS_ON_GET_ONE_FAILED:
    case ordersConstants.ORDERS_ON_DELETE_FAILED:
    case ordersConstants.ORDERS_ON_GET_ALL_FAILED:
    case ordersConstants.ORDERS_ON_GET_ONE_FAILED:
    case rolesConstants.ROLES_ON_DELETE_FAILED:
    case rolesConstants.ROLES_ON_GET_ALL_FAILED:
    case rolesConstants.ROLES_ON_GET_ONE_FAILED:
    case usersConstants.USERS_ON_DELETE_FAILED:
    case usersConstants.USERS_ON_GET_ALL_FAILED:
    case usersConstants.USERS_ON_GET_ONE_FAILED:
      showErrorNotification(error.response?.data?.message || error.message);
      break;
    case categoriesConstants.CATEGORIES_ON_SAVE_FAILED:
    case permissionsConstants.PERMISSIONS_ON_SAVE_FAILED:
    case productsConstants.PRODUCTS_ON_SAVE_FAILED:
    case ordersConstants.ORDERS_ON_SAVE_FAILED:
    case rolesConstants.ROLES_ON_SAVE_FAILED:
    case usersConstants.USERS_ON_SAVE_FAILED:
      if (error.response?.data?.errors?.length)
        error.response.data.errors.forEach((message: string) => showErrorNotification(message));
      else showErrorNotification(error.response?.data?.message || error.message);
      break;
    default:
      if (error?.response?.status === 401)
        showErrorNotification(error.response?.data?.message || error.message);
      break;
  }
  return next(action);
};

export default notificationMiddleware;
