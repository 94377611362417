import { Reducer } from 'redux';

import { IOrdersState } from 'types/orders.types';
import * as authConstants from 'redux/constants/auth.constants';
import * as constants from 'redux/constants/orders.constants';

const defaultState: IOrdersState = {
  count: 0,
  items: [],
  loading: false,
  selectedItem: null,
  activeOrderState: '',
  currentPage: 1,
  lastPage: 1,
  total: 0,
  table: {
    page: 1,
    limit: 10,
    search: '',
    sortKey: 'id',
    sortType: 'asc',
  },
  ordersStates: [],
};

const ordersReducer: Reducer<IOrdersState> = (state = defaultState, action) => {
  const { data, type } = action;
  switch (type) {
    case constants.ORDERS_ON_DELETE_REQUESTED:
    case constants.ORDERS_ON_GET_ALL_REQUESTED:
    case constants.ORDERS_ON_GET_FORM_REQUESTED:
    case constants.ORDERS_ON_SAVE_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case constants.ORDERS_ON_GET_ONE_REQUESTED:
      return {
        ...state,
        loading: !!action.id,
        selectedItem: null,
        activeOrderState: '',
      };
    case constants.ORDERS_ON_GET_ALL_SUCCEEDED:
      return {
        ...state,
        count: data.count,
        items: data.items,
        loading: false,
        table: {
          ...state.table,
          page: data.table.page,
          limit: data.table.limit,
          search: data.table.search,
          sortKey: data.table.sortKey,
          sortType: data.table.sortType,
        },
      };
    case constants.ORDERS_ON_GET_FORM_SUCCEEDED:
      return {
        ...state,
        loading: false,
        ordersStates: data.orderStates,
      };
    case constants.ORDERS_ON_GET_ONE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        selectedItem: data,
        activeOrderState: data.orderHeader.orderState,
      };
    case constants.ORDERS_ON_DELETE_SUCCEEDED:
    case constants.ORDERS_ON_SAVE_SUCCEEDED:
    case constants.ORDERS_ON_DELETE_FAILED:
    case constants.ORDERS_ON_GET_ALL_FAILED:
    case constants.ORDERS_ON_SAVE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case constants.ORDERS_ON_GET_FORM_FAILED:
      return {
        ...state,
        loading: false,
      };
    case constants.ORDERS_ON_GET_ONE_FAILED:
      return {
        ...state,
        loading: false,
        selectedItem: null,
      };
    case authConstants.AUTH_ON_LOGOUT_SUCCEEDED:
      return defaultState;
    default:
      return state;
  }
};

export default ordersReducer;
