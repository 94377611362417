import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import Table from 'components/commons/Table';

import { ViewContainer, ViewContent } from 'styles/view';

import { IProductsViewProps } from './types';

import './i18n';

const ProductsView: FunctionComponent<IProductsViewProps> = (props: IProductsViewProps) => {
  const { columns, count, data, loading, onAddClick, onTableUpdate, table } = props;

  const stockColumnIndex = columns.findIndex(
    (column) => typeof column !== 'string' && column.name === 'stock',
  );

  type ProductRow = {
    stock: number;
    [key: string]: any;
  };

  const updatedData = (data as ProductRow[]).map((row) => ({
    ...row,
    stock: row.stock <= 0 ? 'Sin stock' : row.stock,
  }));

  const tableOptions = {
    setRowProps: (row: any[]) => ({
      style: {
        backgroundColor: row[stockColumnIndex] === 'Sin stock' ? '#ffedf0' : 'transparent',
      },
    }),
  };

  return (
    <ViewContainer auth data-testid="products-view">
      <ViewContent auth>
        <Table
          columns={columns}
          count={count}
          data={updatedData}
          loading={loading}
          onAddClick={onAddClick}
          onTableUpdate={onTableUpdate}
          title={i18next.t<string>('PRODUCTS_VIEW:TITLE')}
          table={{ ...table }}
          options={tableOptions}
        />
      </ViewContent>
    </ViewContainer>
  );
};

export default ProductsView;
