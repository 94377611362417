import React, { FunctionComponent, useEffect, useState } from 'react';
import { Form, Field, useField } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Divider, IconButton } from '@mui/material';
import { AddCircleOutlined, DeleteOutlined } from '@mui/icons-material';
import i18next from 'i18next';

import {
  composeValidators,
  fileValidation,
  requiredValidation,
  requiredArrayValidation,
} from 'helpers/validations';

import Input from 'components/inputs/Input';
import SelectInput from 'components/inputs/SelectInput';

import { ViewContainer, ViewContent, Title, RequiredAsterisk } from 'styles/view';
import Checkbox from 'components/inputs/Checkbox';
import {
  FormContent,
  FormButtonContainer,
  FormSubmitButton,
  FormRowContainer,
  FormAddCombinationButton,
  ColFormRowContainer,
  FormColumnsContainer,
} from 'styles/form';

import { IProductsFormProps } from './types';
import './i18n';

const combinationsField = {
  stock: '',
  stockCategory: '',
  stockDescription: '',
};

const stockCombinationsField = {
  combinations: [],
  ...combinationsField,
};

const ProductsForm: FunctionComponent<IProductsFormProps> = (props: IProductsFormProps) => {
  const {
    categories,
    initialValues,
    isEditing,
    files,
    onSubmit,
    stockCategories,
    stockDescriptions,
    suppliers,
    tags,
  } = props;

  const sizeLabel = 'Talles/Medidas';
  const [updatedStockCategories, setUpdatedStockCategories] = useState(stockCategories);

  useEffect(() => {
    const categoriesClone = [...stockCategories];
    const index = categoriesClone.findIndex((stockCategory) => stockCategory.label === 'Talles');
    if (index >= 0) {
      categoriesClone[index].label = sizeLabel;
    }
    setUpdatedStockCategories(categoriesClone);
  }, [stockCategories]);

  const selectedCategories = new Map();

  function getValue(index1: number, index2: number | null) {
    const categoryMap = selectedCategories.get(index1);
    return categoryMap ? categoryMap.get(index2) : null;
  }

  const StockCategoryFieldComplement = ({
    name,
    parent,
    index1,
    index2,
  }: {
    name: string;
    parent: string;
    index1: number;
    index2: number;
  }) => {
    const field = useField(name);
    const parentField = useField(parent);
    const [options, setOptions] = useState(updatedStockCategories);
    const label = i18next.t<string>('PRODUCTS_FORM:FEATURE_TYPE');

    useEffect(() => {
      const array = updatedStockCategories.filter(
        (stockCategory) => stockCategory.value !== parentField.input.value,
      );
      setOptions(array);
      const exists = array.some((stockCategory) => stockCategory.value === field.input.value);
      const category = updatedStockCategories.find((stockCategory) => {
        return field.input.value === stockCategory.value;
      })?.label;

      if (!selectedCategories.has(index1)) {
        selectedCategories.set(index1, new Map());
      }
      const categoryMap = selectedCategories.get(index1);

      if (categoryMap instanceof Map) {
        categoryMap.set(index2, { category });
      } else if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(`Expected categoryMap to be a Map, but got ${typeof categoryMap}`);
      }

      if (!exists) field.input.onChange('');
    }, [field, parentField, index1, index2]);

    return (
      <Field
        label={label}
        name={name}
        options={options}
        render={SelectInput}
        validate={requiredValidation}
      />
    );
  };

  const StockCategoryField = ({
    name,
    parent,
    index,
  }: {
    name: string;
    parent: string;
    index: number;
  }) => {
    const colorLabel = 'Colores';
    const field = useField(name);
    const initialCategory =
      field.input.value || updatedStockCategories.find((s) => s.label === colorLabel)?.value;
    const parentField = useField(parent);
    const [options, setOptions] = useState(updatedStockCategories);

    useEffect(() => {
      const availableOptions = updatedStockCategories.filter(
        (stockCategory) => stockCategory.label === colorLabel || stockCategory.label === sizeLabel,
      );
      setOptions(availableOptions);

      if (!parentField.input.value) return;

      const category = updatedStockCategories.find((stockCategory) => {
        return parentField.input.value === stockCategory.value;
      })?.label;

      if (!selectedCategories.has(index)) {
        selectedCategories.set(index, new Map());
      }

      const categoryMap = selectedCategories.get(index);

      if (categoryMap instanceof Map) {
        categoryMap.set(null, { category });
      } else if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(`Expected categoryMap to be a Map, but got ${typeof categoryMap}`);
      }
    }, [parentField.input.value, index]);

    useEffect(() => {
      if (!field.input.value) {
        field.input.onChange(initialCategory);

        if (!selectedCategories.has(index)) {
          selectedCategories.set(index, new Map());
        }
        const categoryMap = selectedCategories.get(index);
        if (categoryMap instanceof Map) {
          const categoryLabel = updatedStockCategories.find(
            (stockCategory) => stockCategory.value === initialCategory,
          )?.label;
          categoryMap.set(null, { category: categoryLabel });
        }
      }
    }, [initialCategory, field.input, index]);

    return (
      <Field
        label={i18next.t<string>('PRODUCTS_FORM:FEATURE_TYPE')}
        name={name}
        options={options}
        render={SelectInput}
        validate={requiredValidation}
      />
    );
  };

  const StockDescriptionField = ({
    name,
    parent,
    index1,
    index2,
  }: {
    name: string;
    parent: string;
    index1: number;
    index2: number | null;
  }) => {
    const field = useField(name);
    const parentField = useField(parent);
    const [options, setOptions] = useState(stockDescriptions);
    const [descriptionLabel, setDescriptionLabel] = useState(
      i18next.t<string>('PRODUCTS_FORM:STOCK_DESCRIPTION'),
    );

    useEffect(() => {
      if (!parentField.input.value) return;
      const array = stockDescriptions.filter(
        (stockDescription) => stockDescription.data === parentField.input.value,
      );

      setOptions(array);
      const exists = array.some((stockDescription) => stockDescription.value === field.input.value);
      if (!exists) field.input.onChange('');
    }, [parentField.input.value, field.input]);

    useEffect(() => {
      const selectedCategory = getValue(index1, index2);
      switch (selectedCategory?.category) {
        case 'Colores':
          setDescriptionLabel(i18next.t<string>('PRODUCTS_FORM:COLOR_SELECT'));
          break;
        case sizeLabel:
          setDescriptionLabel(i18next.t<string>('PRODUCTS_FORM:SIZE_SELECT'));
          break;
        case 'Diseños':
          setDescriptionLabel(i18next.t<string>('PRODUCTS_FORM:DESIGN_SELECT'));
          break;
        default:
          setDescriptionLabel(i18next.t<string>('PRODUCTS_FORM:STOCK_DESCRIPTION'));
      }
    }, [index1, index2, parentField.input.value, field.input.value]);

    return (
      <Field
        label={descriptionLabel}
        name={name}
        options={options}
        render={SelectInput}
        validate={requiredValidation}
      />
    );
  };

  const StockField = ({ name }: { name: string }) => {
    const field = useField(`${name}.combinations`);

    return !field.input.value?.length ? (
      <Field
        label={i18next.t<string>('PRODUCTS_FORM:STOCK')}
        name={`${name}.stock`}
        render={Input}
        type="number"
        validate={requiredValidation}
      />
    ) : null;
  };

  return (
    <ViewContainer auth data-testid="products-form">
      <ViewContent auth>
        <Title>{i18next.t<string>('PRODUCTS_FORM:TITLE')}</Title>
        <Form
          initialValues={initialValues}
          mutators={{ ...arrayMutators }}
          onSubmit={onSubmit}
          render={({
            form: {
              mutators: { push, remove },
            },
            handleSubmit,
            submitting,
            pristine,
          }) => (
            <FormContent onSubmit={handleSubmit}>
              <FormColumnsContainer>
                <ColFormRowContainer>
                  <Field
                    label={
                      <>
                        {i18next.t<string>('PRODUCTS_FORM:NAME')}
                        <RequiredAsterisk>*</RequiredAsterisk>
                      </>
                    }
                    name="name"
                    render={Input}
                    validate={requiredValidation}
                  />
                </ColFormRowContainer>
                <ColFormRowContainer>
                  <Field
                    label={
                      <>
                        {i18next.t<string>('PRODUCTS_FORM:DESCRIPTION')}
                        <RequiredAsterisk>*</RequiredAsterisk>
                      </>
                    }
                    maxLength={65535}
                    name="description"
                    render={Input}
                    type="textarea"
                    validate={requiredValidation}
                  />
                </ColFormRowContainer>
                <ColFormRowContainer>
                  <Field
                    label={i18next.t<string>('PRODUCTS_FORM:CATEGORIES')}
                    multiple
                    name="categories"
                    options={categories}
                    render={(properties) => <SelectInput {...properties} required />}
                    validate={requiredArrayValidation}
                  />
                </ColFormRowContainer>
                <ColFormRowContainer>
                  <Field
                    label={i18next.t<string>('PRODUCTS_FORM:TAGS')}
                    multiple
                    name="tags"
                    options={tags}
                    render={SelectInput}
                  />
                </ColFormRowContainer>
                <ColFormRowContainer>
                  <Field
                    label={
                      <>
                        {i18next.t<string>('PRODUCTS_FORM:STOCK')}
                        <RequiredAsterisk>*</RequiredAsterisk>
                      </>
                    }
                    name="stock"
                    render={Input}
                    type="number"
                    validate={requiredValidation}
                  />
                </ColFormRowContainer>
                <ColFormRowContainer>
                  <Field
                    label={
                      <>
                        {i18next.t<string>('PRODUCTS_FORM:PRICE')}
                        <RequiredAsterisk>*</RequiredAsterisk>
                      </>
                    }
                    name="price"
                    render={Input}
                    type="number"
                    validate={requiredValidation}
                  />
                </ColFormRowContainer>
                <ColFormRowContainer>
                  <Field
                    label={
                      <>
                        {i18next.t<string>('PRODUCTS_FORM:SUPPLIER')}
                        <RequiredAsterisk>*</RequiredAsterisk>
                      </>
                    }
                    name="supplierId"
                    options={suppliers}
                    render={(properties) => <SelectInput {...properties} required />}
                    validate={requiredValidation}
                  />
                </ColFormRowContainer>
                <ColFormRowContainer>
                  <Field
                    label={
                      <>
                        {i18next.t<string>('PRODUCTS_FORM:COST')}
                        <RequiredAsterisk>*</RequiredAsterisk>
                      </>
                    }
                    name="costo"
                    render={Input}
                    type="number"
                    validate={requiredValidation}
                  />
                </ColFormRowContainer>
                <ColFormRowContainer>
                  <Field
                    label={i18next.t<string>('PRODUCTS_FORM:QUANTITY_LIMIT')}
                    name="qtyBuy"
                    render={Input}
                    type="number"
                  />
                </ColFormRowContainer>
                <ColFormRowContainer>
                  <Field label={i18next.t<string>('PRODUCTS_FORM:SKU')} name="sku" render={Input} />
                </ColFormRowContainer>
                <ColFormRowContainer>
                  <Field
                    label={i18next.t<string>('PRODUCTS_FORM:OFFER_PRICE')}
                    multiple
                    name="sale"
                    render={Input}
                    type="number"
                  />
                </ColFormRowContainer>
                <ColFormRowContainer>
                  <Field
                    label={i18next.t<string>('PRODUCTS_FORM:SHOW_ON_NEW_14')}
                    multiple
                    name="showtime"
                    render={Checkbox}
                  />
                </ColFormRowContainer>
                <ColFormRowContainer>
                  <Field
                    label={i18next.t<string>('PRODUCTS_FORM:DROP_SHIPPING')}
                    name="dropShipping"
                    render={Checkbox}
                  />
                </ColFormRowContainer>
                <ColFormRowContainer>
                  <Field
                    label={i18next.t<string>('PRODUCTS_FORM:SHOW_AS_NEW')}
                    name="new"
                    render={Checkbox}
                  />
                </ColFormRowContainer>
                <ColFormRowContainer>
                  <Field
                    label={
                      <>
                        {i18next.t<string>('PRODUCTS_FORM:IMAGE1')}
                        <RequiredAsterisk>*</RequiredAsterisk>
                      </>
                    }
                    name="image1"
                    preview={files?.image1}
                    render={Input}
                    type="file"
                    validate={
                      isEditing
                        ? fileValidation
                        : composeValidators(requiredValidation, fileValidation)
                    }
                  />
                </ColFormRowContainer>
                <ColFormRowContainer>
                  <Field
                    label={i18next.t<string>('PRODUCTS_FORM:IMAGE2')}
                    name="image2"
                    preview={files?.image2}
                    render={Input}
                    type="file"
                    validate={fileValidation}
                  />
                </ColFormRowContainer>
                <ColFormRowContainer>
                  <Field
                    label={i18next.t<string>('PRODUCTS_FORM:IMAGE3')}
                    name="image3"
                    preview={files?.image3}
                    render={Input}
                    type="file"
                    validate={fileValidation}
                  />
                </ColFormRowContainer>
                <ColFormRowContainer>
                  <Field
                    label={i18next.t<string>('PRODUCTS_FORM:IMAGE4')}
                    name="image4"
                    preview={files?.image4}
                    render={Input}
                    type="file"
                    validate={fileValidation}
                  />
                </ColFormRowContainer>
                <ColFormRowContainer>
                  <Field
                    label={i18next.t<string>('PRODUCTS_FORM:IMAGE5')}
                    name="image5"
                    preview={files?.image5}
                    render={Input}
                    type="file"
                    validate={fileValidation}
                  />
                </ColFormRowContainer>
                <ColFormRowContainer>
                  <Field
                    label={i18next.t<string>('PRODUCTS_FORM:IMAGE6')}
                    name="image6"
                    preview={files?.image6}
                    render={Input}
                    type="file"
                    validate={fileValidation}
                  />
                </ColFormRowContainer>
              </FormColumnsContainer>
              <FieldArray name="stockCombinations">
                {({ fields: stockCombinations }) =>
                  stockCombinations.map((name, i) => (
                    <div key={name}>
                      <Divider>
                        {`${i18next.t<string>('PRODUCTS_FORM:PRODUCT_DETAIL')} ${i + 1}`}
                        <IconButton onClick={() => remove('stockCombinations', i)}>
                          <DeleteOutlined />
                        </IconButton>
                      </Divider>
                      <FormRowContainer>
                        <StockCategoryField
                          name={`${name}.stockCategory`}
                          parent={`${name}.stockCategory`}
                          index={i}
                        />
                        <StockDescriptionField
                          name={`${name}.stockDescription`}
                          parent={`${name}.stockCategory`}
                          index1={i}
                          index2={null}
                        />
                        <StockField name={name} />
                        <IconButton
                          onClick={() => push(`${name}.combinations`, combinationsField)}
                          style={{ height: 60 }}
                        >
                          <AddCircleOutlined />
                        </IconButton>
                      </FormRowContainer>
                      <FieldArray name={`${name}.combinations`}>
                        {({ fields: combinations }) =>
                          combinations.map((name2, j) => (
                            <FormRowContainer key={name2}>
                              <StockCategoryFieldComplement
                                name={`${name2}.stockCategory`}
                                parent={`${name}.stockCategory`}
                                index1={i}
                                index2={j}
                              />
                              <StockDescriptionField
                                name={`${name2}.stockDescription`}
                                parent={`${name2}.stockCategory`}
                                index1={i}
                                index2={j}
                              />
                              <Field
                                label={i18next.t<string>('PRODUCTS_FORM:STOCK')}
                                name={`${name2}.stock`}
                                render={Input}
                                type="number"
                                validate={requiredValidation}
                              />
                              <IconButton
                                onClick={() => remove(`${name}.combinations`, j)}
                                style={{ height: 60 }}
                              >
                                <DeleteOutlined />
                              </IconButton>
                            </FormRowContainer>
                          ))
                        }
                      </FieldArray>
                    </div>
                  ))
                }
              </FieldArray>
              <FormAddCombinationButton
                onClick={() => push('stockCombinations', stockCombinationsField)}
                variant="outlined"
              >
                {i18next.t<string>('PRODUCTS_FORM:PRODUCT_DETAIL_BUTTON')}
              </FormAddCombinationButton>
              <FormButtonContainer>
                <FormSubmitButton disabled={pristine || submitting} type="submit">
                  {i18next.t<string>('PRODUCTS_FORM:SUBMIT_BUTTON')}
                </FormSubmitButton>
              </FormButtonContainer>
            </FormContent>
          )}
        />
      </ViewContent>
    </ViewContainer>
  );
};

export default ProductsForm;
