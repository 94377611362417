import React, { FunctionComponent } from 'react';
import { Form, Field } from 'react-final-form';
import i18next from 'i18next';

import { requiredValidation } from 'helpers/validations';

import SelectInput from 'components/inputs/SelectInput';

import { ViewContainer, ViewContent, Title } from 'styles/view';
import { FormContent, FormButtonContainer, FormSubmitButton } from 'styles/form';

import { IOrdersFormProps } from './types';
import './i18n';

const OrdersForm: FunctionComponent<IOrdersFormProps> = (props: IOrdersFormProps) => {
  const { initialValues, isEditing, onSubmit, orderStates } = props;

  const orderStatesOptions = orderStates.map((state) => ({ value: state, label: state }));

  return (
    <ViewContainer auth data-testid="orders-form">
      <ViewContent auth>
        <Title>{i18next.t<string>('ORDERS_FORM:TITLE')}</Title>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, pristine }) => (
            <FormContent onSubmit={handleSubmit}>
              <Field
                name="ordersState"
                label={i18next.t<string>('ORDERS_FORM:ORDER_STATE')}
                options={orderStatesOptions}
                render={SelectInput}
                validate={requiredValidation}
              />
              <FormButtonContainer>
                <FormSubmitButton disabled={pristine || submitting} type="submit">
                  {isEditing
                    ? i18next.t<string>('ORDERS_FORM:UPDATE_BUTTON')
                    : i18next.t<string>('ORDERS_FORM:CREATE_BUTTON')}
                </FormSubmitButton>
              </FormButtonContainer>
            </FormContent>
          )}
        />
      </ViewContent>
    </ViewContainer>
  );
};

export default OrdersForm;
