import React, { FunctionComponent, useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { onDelete, onGetAll } from 'redux/actions/orders.actions';
import { ordersSelector } from 'redux/selectors/orders.selector';
import { history } from 'helpers/history';
import { COLORS } from 'helpers/enums/colors';

import LayoutContainer from 'containers/LayoutContainer';
import OrdersView from 'views/OrdersView';
import ActionButtons from 'components/commons/Table/components/ActionButtons';
import Dialog from 'components/commons/Dialog';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { IOrder } from 'types/orders.types';
import { ITableUpdate } from 'components/commons/Table/types';

import 'views/OrdersView/i18n';

const OrdersContainer: FunctionComponent = () => {
  const dispatch = useDispatch();

  const [deleteItem, setDeleteItem] = useState<IOrder | undefined>();
  const ordersState = useTypedSelector(ordersSelector);
  const { count, loading, table } = ordersState;
  const items = ordersState.items || [];

  useEffect(() => {
    dispatch(onGetAll({ limit: 10, page: 1, search: '', sortKey: 'id', sortType: 'desc' }));
  }, [dispatch]);

  const actions = [
    {
      label: i18next.t('ORDERS_VIEW:EDIT'),
      onClick: (id: string) => history.push(`/orders/${id}/edit`),
    },
    {
      label: i18next.t('ORDERS_VIEW:DELETE'),
      onClick: (id: string) => setDeleteItem(items.find((item: IOrder) => item.id === Number(id))),
      color: COLORS.redMonza,
    },
  ];

  const columns = [
    {
      name: 'id',
      label: i18next.t('ORDERS_VIEW:ORDER_ID'),
      options: {
        customBodyRender: (value: string) => (
          <button
            type="button"
            style={{
              cursor: 'pointer',
              color: 'blue',
              background: 'none',
              border: 'none',
              padding: 0,
            }}
            onClick={() => history.push(`/orders/${value}/show`)}
          >
            {value}
            <VisibilityIcon fontSize="small" sx={{ paddingTop: '3px' }} />
          </button>
        ),
      },
    },
    {
      name: 'name',
      label: i18next.t('ORDERS_VIEW:CUSTOMER'),
      options: {
        sort: false,
      },
    },
    {
      name: 'date',
      label: i18next.t('ORDERS_VIEW:PURCHASE_DATE'),
    },
    {
      name: 'orderState',
      label: i18next.t('ORDERS_VIEW:ORDER_STATE'),
      options: {
        sort: false,
      },
    },
    {
      name: 'paymentMethod',
      label: i18next.t('ORDERS_VIEW:SHIPPING_METHOD'),
      options: {
        sort: false,
      },
    },
    {
      name: 'subtotal',
      label: i18next.t('ORDERS_VIEW:SUBTOTAL'),
      options: {
        customBodyRender: (value: number) => `$${value}`,
      },
    },
    {
      name: 'total',
      label: i18next.t('ORDERS_VIEW:TOTAL_PLUS_SHIPPING'),
      options: {
        customBodyRenderLite: (dataIndex: number) => {
          const row = items[dataIndex];
          const total = (row.total || 0) + (row.costoEnvio || 0);
          return `$${total.toFixed(2)}`;
        },
      },
    },
    {
      name: 'web',
      label: i18next.t('ORDERS_VIEW:WEB_PURCHASE'),
      options: {
        customBodyRender: (value: boolean) => (value ? 'Sí' : 'No'),
        sort: false,
      },
    },
    {
      name: 'id',
      label: i18next.t('ORDERS_VIEW:ACTIONS'),
      options: {
        customBodyRender: (value: string) => <ActionButtons id={value} items={actions} />,
        sort: false,
      },
    },
  ];

  const onTableUpdate = useCallback((data: ITableUpdate) => dispatch(onGetAll(data)), [dispatch]);

  const onDeleteClick = (isConfirmed = false) => {
    if (isConfirmed && deleteItem) dispatch(onDelete(deleteItem.id.toString()));
    setDeleteItem(undefined);
  };

  return (
    <LayoutContainer auth title={i18next.t<string>('ORDERS_VIEW:TITLE')}>
      <OrdersView
        columns={columns}
        count={count}
        data={items}
        loading={loading}
        onAddClick={() => history.push('/orders/create')}
        onTableUpdate={onTableUpdate}
        table={table}
      />
      <Dialog
        message={i18next.t('ORDERS_VIEW:DELETE_DIALOG_MESSAGE', {
          id: deleteItem?.id,
        })}
        onCancelClick={() => onDeleteClick()}
        onConfirmClick={() => onDeleteClick(true)}
        open={!!deleteItem}
        title={i18next.t('ORDERS_VIEW:DELETE_DIALOG_TITLE')}
      />
    </LayoutContainer>
  );
};

export default OrdersContainer;
