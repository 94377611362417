import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import * as constants from 'redux/constants/orders.constants';
import { getAll, getForm, getOne, save, softDelete } from 'services/orders.services';

export function* ordersDelete(action: AnyAction): Generator {
  try {
    const { id }: any = action;
    const { data }: any = yield call(softDelete, id);
    yield put({
      type: constants.ORDERS_ON_DELETE_SUCCEEDED,
      data: { ...data, id },
    });
  } catch (error) {
    yield put({ type: constants.ORDERS_ON_DELETE_FAILED, error });
  }
}

export function* ordersGetAll(action: AnyAction): Generator {
  try {
    const { limit, page, search, sortKey, sortType } = action;
    const { data }: any = yield call(getAll, limit, page, search, sortKey, sortType);
    if (data) {
      yield put({ type: constants.ORDERS_ON_GET_ALL_SUCCEEDED, data });
    } else {
      yield put({ type: constants.ORDERS_ON_GET_ALL_FAILED, error: 'No data returned' });
    }
  } catch (error: any) {
    yield put({
      type: constants.ORDERS_ON_GET_ALL_FAILED,
      error: error.message || 'Connection error',
    });
  }
}

export function* ordersGetForm(): Generator {
  try {
    const { data }: any = yield call(getForm);
    yield put({ type: constants.ORDERS_ON_GET_FORM_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.ORDERS_ON_GET_FORM_FAILED, error });
  }
}

export function* ordersGetOne(action: AnyAction): Generator {
  try {
    const { id }: any = action;
    if (id) {
      const { data }: any = yield call(getOne, id);
      yield put({ type: constants.ORDERS_ON_GET_ONE_SUCCEEDED, data });
    }
  } catch (error) {
    yield put({ type: constants.ORDERS_ON_GET_ONE_FAILED, error });
  }
}

export function* ordersSave(action: AnyAction): Generator {
  try {
    const { formData, id } = action;
    const { data }: any = yield call(save, formData, id);
    yield put({ type: constants.ORDERS_ON_SAVE_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.ORDERS_ON_SAVE_FAILED, error });
  }
}

export function* ordersRefresh(): Generator {
  const table: any = yield select((state) => state.orders.table);
  yield put({ type: constants.ORDERS_ON_GET_ALL_REQUESTED, ...table });
}

export function* watchOrders(): Generator {
  yield all([
    takeLatest(constants.ORDERS_ON_DELETE_REQUESTED, ordersDelete),
    takeLatest(constants.ORDERS_ON_GET_ALL_REQUESTED, ordersGetAll),
    takeLatest(constants.ORDERS_ON_GET_FORM_REQUESTED, ordersGetForm),
    takeLatest(constants.ORDERS_ON_GET_ONE_REQUESTED, ordersGetOne),
    takeLatest(constants.ORDERS_ON_SAVE_REQUESTED, ordersSave),
    takeLatest(
      [constants.ORDERS_ON_DELETE_SUCCEEDED, constants.ORDERS_ON_SAVE_SUCCEEDED],
      ordersRefresh,
    ),
  ]);
}
