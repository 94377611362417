import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Close } from '@mui/icons-material';

import { SIDEBAR_WIDTH } from 'helpers/dimensions';
import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

export const SidebarContainer = styled.div<{ open: boolean }>`
  position: fixed;
  min-width: ${SIDEBAR_WIDTH}px;
  height: 100vh;
  background-color: ${COLORS.white};
  box-shadow: ${COLORS.boxShadow};
  overflow: hidden;
  transition: min-width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 10;

  @media (max-width: ${SIZES.bigTablet}px) {
    width: ${({ open }) => (open ? `${SIDEBAR_WIDTH}px` : '0')};
    min-width: 0;
    transition: width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
`;

export const SidebarContent = styled.ul`
  height: 100%;
  margin: 15px;
  padding: 0 0 20px 0;
  overflow: hidden;
`;

export const SidebarToggleMenu = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 20px 10px;
`;

export const SidebarCloseButton = styled(Close)`
  && {
    color: ${COLORS.grayScorpion};
    cursor: pointer;

    &:hover {
      color: ${COLORS.orangeOutrageous};
    }
  }
`;

export const SidebarItem = styled(NavLink)<{ isSubItem?: boolean; open: boolean }>`
  && {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
    padding: 10px;
    padding-left: ${({ isSubItem }) => (isSubItem ? '15px' : 'auto')};
    color: ${COLORS.grayScorpion};
    border-radius: 4px;

    &.active {
      background-color: ${COLORS.orangeOutrageous};
      color: ${COLORS.white};
    }

    span {
      margin-left: 10px;
      font-size: 14px;
    }

    &:hover {
      padding-left: ${({ isSubItem }) => (isSubItem ? 20 : 15)}px;
      transition: padding 0.35s ease 0s !important;
    }

    &:not(:hover) {
      padding-right: 15px;
      transition: padding 0.35s ease 0s !important;
    }
  }
`;

export const SidebarItemArrow = styled.div<{ rotated: boolean }>`
  height: 24px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%) ${({ rotated }) => (rotated ? 'rotate(90deg)' : '')};
  transition: all 0.25s ease;
`;

export const SidebarItemSubMenu = styled.div<{ show: boolean }>`
  height: 0;
  opacity: 0;
  transition: all 0.25s ease;
  overflow: hidden;

  ${({ show }) =>
    show &&
    css`
      height: 100%;
      opacity: 1;
    `}
`;

export const SidebarOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: ${COLORS.modalBackground};
  z-index: 5;
`;
