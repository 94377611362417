import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { onGetOne } from 'redux/actions/orders.actions';

import LayoutContainer from 'containers/LayoutContainer';
import Loading from 'components/commons/Loading';
import OrderDetailsView from 'views/OrderDetailsView';

import 'views/OrderDetailsView/i18n';

const OrderDetailsContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id = '' } = useParams<{ id?: string }>();
  const order = useTypedSelector((state) => state.orders.selectedItem);
  const { loading } = useTypedSelector((state) => state.orders);
  const title: string = i18next.t('ORDER_DETAILS_VIEW:TITLE');

  useEffect(() => {
    if (id) {
      dispatch(onGetOne(id));
    }
  }, [dispatch, id]);

  return (
    <LayoutContainer auth title={title}>
      {loading && <Loading isModalMode message={i18next.t('ORDER_DETAILS_VIEW:LOADING')} />}
      {!loading && order ? (
        <OrderDetailsView order={order} data={[]} loading={loading} />
      ) : (
        !loading && <div>{title}</div>
      )}
    </LayoutContainer>
  );
};

export default OrderDetailsContainer;
